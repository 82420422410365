@font-face {
  font-family: "Poppins";
  src: local("PoppinsRegular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsMedium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsSemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsBold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: local("PoppinsExtraBold"),
    url("./fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}


.App {
  text-align: center;
  font-family: "Poppins","sans-serif";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div {
  box-sizing: border-box;
}

p {
  margin: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

/* Fade out animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 1s ease-in-out;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.footprint.active {
  color: red; /* Active color for the footprints */
  animation: bounce 1s infinite;
}

@media (max-width: 600px) {
  .pack-description span {
    font-size: 24px !important;
  }

  .challenge-indication span {
    font-size: 16px !important;
  }
}